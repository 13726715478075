import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Emp_barchart = ({ data }) => {
  const chartData = [
    { name: "Total Workforce", value: data.totalWorkforce, fill: "#011f4b" },
    {
      name: "Present Workforce",
      value: data.presentWorkforce,
      fill: "#03396c",
    },
    { name: "Absent Workforce", value: data.absentWorkforce, fill: "#005b96" },
    { name: "Late Arrivals", value: data.lateArrivals, fill: "#6497b1" },
    { name: "In Leave", value: data.inLeave, fill: "#b3cde0" },
  ];

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        layout="vertical"
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" />
        <YAxis type="category" dataKey="name" />
        <Tooltip />
        <Bar dataKey="value" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default Emp_barchart;
