import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Import the styles for Toastify
import { MdOutlineFileDownload } from "react-icons/md";

const ResignationAndTermination = () => {
  const [statusChanges, setStatusChanges] = useState([]);
  const [employeeNo, setEmployeeNo] = useState("");
  const [type, setType] = useState("");
  const [date, setDate] = useState("");
  const [reason, setReason] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false); // State to manage the loader visibility
  const API_URL = "https://back-serendipity-hris.quortech-technologies.com";

  useEffect(() => {
    fetchStatusChanges();
  }, []);

  const fetchStatusChanges = async () => {
    try {
      const response = await fetch(
        `${API_URL}/v1/hris/employeeStatusChange/status-changes`
      );
      const data = await response.json();
      setStatusChanges(data);
    } catch (error) {
      console.error("Error fetching status changes:", error);
      toast.error("Failed to fetch status changes. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Submit form data (employeeNo, type, date, reason) via POST request
    const formData = {
      employeeId: employeeNo,
      type,
      date,
      reason,
    };

    // First POST Request (Form Submission)
    try {
      const formResponse = await fetch(
        `${API_URL}/v1/hris/employeeStatusChange/status-changes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      const formDataResult = await formResponse.json();
      console.log("Form Submitted:", formDataResult);
      toast.success("Form submitted successfully!");

      // Refresh table data after form submission
      fetchStatusChanges();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Something went wrong. Please try again later.");
    }

    // Second POST Request (File Upload)
    const formDataForFile = new FormData();
    formDataForFile.append("employee_no", employeeNo);
    formDataForFile.append("files", file);

    // Start the loading state before the file upload
    setLoading(true);

    try {
      const fileResponse = await fetch(
        `${API_URL}/v1/hris/employees/status-changes`,
        {
          method: "POST",
          body: formDataForFile,
        }
      );
      const fileResult = await fileResponse.json();
      console.log("File Uploaded:", fileResult);
      toast.success("File uploaded successfully!");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(
        "Something went wrong while uploading the file. Please try again later."
      );
    } finally {
      // Stop the loading state after the upload completes
      setLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-4 mx-5 mt-5 font-montserrat">
      {/* Table Section */}
      <div className="shadow-lg p-5 rounded-lg">
        <p className="text-[20px] font-semibold mb-4">
          Resignation/Termination Records
        </p>
        <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-6 py-4 font-medium text-gray-900">
                Employee No
              </th>
              <th className="px-6 py-4 font-medium text-gray-900">Type</th>
              <th className="px-6 py-4 font-medium text-gray-900">Date</th>
              <th className="px-6 py-4 font-medium text-gray-900">Reason</th>
              <th className="px-6 py-4 font-medium text-gray-900">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 border-t border-gray-200">
            {statusChanges.map((change) => (
              <tr key={change.id} className="hover:bg-gray-50">
                <td className="px-6 py-4">{change.employeeId}</td>
                <td className="px-6 py-4">{change.type}</td>
                <td className="px-6 py-4">
                  {new Date(change.date).toLocaleDateString()}
                </td>
                <td className="px-6 py-4">{change.reason}</td>
                <td className="px-6 py-4">
                  <a
                    href={`${API_URL}/${change.documentUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline flex items-center gap-2"
                  >
                    <MdOutlineFileDownload /> Download
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Form Section */}
      <div className="shadow-lg p-5 rounded-lg">
        <p className="text-[20px] font-semibold mb-4">
          Submit Resignation/Termination
        </p>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 grid-flow-row gap-4">
            <div>
              <label>Employee No</label>
              <input
                type="text"
                className="border border-gray-500 p-2 rounded-md w-full"
                value={employeeNo}
                onChange={(e) => setEmployeeNo(e.target.value)}
                placeholder="Enter Employee No"
              />
            </div>

            <div>
              <label>Type</label>
              <select
                className="border border-gray-500 p-2 rounded-md w-full"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">Select Type</option>
                <option value="resignation">Resignation</option>
                <option value="termination">Termination</option>
              </select>
            </div>

            <div>
              <label>Date</label>
              <input
                type="date"
                className="border border-gray-500 p-2 rounded-md w-full"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>

            <div>
              <label>Reason</label>
              <input
                type="text"
                className="border border-gray-500 p-2 rounded-md w-full"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
          </div>

          <div className="mt-4">
            <label className="block">Upload File</label>
            <input
              type="file"
              accept="application/pdf, .doc, .docx, .jpg, .jpeg, .png"
              className="block w-full mt-2 p-2 border border-gray-500 rounded-md cursor-pointer"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>

          {loading && (
            <div className="mt-4 flex justify-center">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
          )}

          <div className="mt-4">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg w-full"
              disabled={loading}
            >
              Submit
            </button>
          </div>
        </form>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ResignationAndTermination;
