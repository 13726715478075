

import React from 'react';

const leavesData = [
  { name: "Total Workforce", color: '#011f4b' },
  { name: "Present Workforce", color: '#03396c' },
  { name: "Absent Workforce", color: '#005b96' },
  { name: "Late Arrivals", color: '#6497b1' },
  { name: "In Leave", color: '#b3cde0' },
];

const LeaveDetails = () => {
  return (
    <div className="border-2 border-gray-100 shadow-xl bg-white p-4 w-[200px] rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Workforce Summary</h2>
      <ul>
        {leavesData.map((leave, index) => (
          <li key={index} className="flex items-center mb-2">
            <span className={`w-4 h-4 rounded-full mr-2`} style={{ backgroundColor: `${leave.color}` }} />
            <span>{leave.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeaveDetails;
